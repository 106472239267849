
























































import { Vue, Component } from 'vue-property-decorator'
import gql from 'graphql-tag'
import { Room } from '../../types/classes'

@Component
export default class extends Vue {
  private rooms: Partial<Room>[] = []
  private addRoomDialog = false
  private roomName = ''
  private onUpdate = false

  mounted() {
    this.fetchRoom()
  }

  private async fetchRoom() {
    const { data } = await this.$apollo.query<{ rooms: Partial<Room>[] }>({
      query: gql`
        query QueryAllRooms {
          rooms {
            id
            name
          }
        }
      `,
      fetchPolicy: 'no-cache',
    })
    if (data) {
      this.rooms = data.rooms
    }
  }

  private async addNewRoom() {
    if (this.roomName.length > 0) {
      this.onUpdate = true
      try {
        const { data } = await this.$apollo.mutate<{
          createRoom: Partial<Room>
        }>({
          mutation: gql`
            mutation CreateNewRoom($data: CreateRoomInput!) {
              createRoom(data: $data) {
                id
                name
              }
            }
          `,
          variables: {
            data: {
              name: this.roomName,
            },
          },
        })
        if (data) {
          this.rooms.push(data.createRoom)
        }
      } catch (error) {
        // empty
      } finally {
        this.addRoomDialog = false
        this.onUpdate = false
        this.roomName = ''
      }
    }
  }
}
